import * as React from "react";
import { graphql, Link } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";

export const Tags = (props) => {
  const { data } = props;
  const queryData: QueryRes = data;
  const tagGroup = queryData.allMarkdownRemark.group;
  return (
    <Layout>
      <Seo title="Alle Beiträge von Lars" />
      <Bio />
      <ul>
        {tagGroup.map((x) => (
          <li>
            {x.fieldValue}
            <ol>
              {x.edges.map((edge) => (
                <li>
                  <Link to={edge.node.fields.slug}>
                    {edge.node.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default Tags;

type QueryRes = {
  allMarkdownRemark: {
    group: {
      fieldValue: string;
      totalCount: number;
      edges: {
        node: {
          id: string;
          frontmatter: {
            title: string;
            date: string;
            description: string;
          };
          fields: {
            slug: string;
          };
        };
      }[];
    }[];
  };
};
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              description
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
